import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  setGlossaryStateOpen,
  setGlossaryTag,
} from '../../containers/DashBoard/actions';
import messages from '../../containers/VehicleProductsView/messages';
import { checkUserEntitlements } from '../../userConfig';
import Diagnostic from '../Diagnostic';
import EventList from '../Events';
import { IconInfo } from '../Icons';
import Loading from '../Loading';
import ProductStatusTabs from '../ProductStatusTabs';
import ProductUsagesTabs from '../ProductUsagesTabs';
import SingleGraph from '../SingleGraph';
import SoCTable from '../SoCTable';
import SynchronizedGraph from '../SynchronizedGraph';
import SignalSelector from '../Title/SignalSelector';
import {
  mainSignalTrendOptions,
  mainSignalTrendOptionsDemo,
  overlaySignalOptions,
  overlaySignalOptionsDemo,
} from './constants';
import './styles.scss';
class VehicleProductsTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      isFixed: false,
      showThreshold: false,
      showDiv: true,
      paramsArray: [
        'Temperature',
        'State of Charge',
        'Power',
        'Battery Current',
      ],
    };
  }

  handleScroll = () => {
    const isFixed = window.scrollY >= 300;
    if (isFixed !== this.state.isFixed) {
      this.setState({ isFixed });
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.checkParams();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  openGlossary = () => {
    const selectedTab = this.props.selectedTab;
    if (selectedTab.includes('Timetrend')) {
      this.props.setGlossaryStateOpen(true);
      this.props.setGlossaryTag('Time trend');
    } else {
      this.props.setGlossaryStateOpen(true);
      this.props.setGlossaryTag('Product State of Health');
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isResizing !== this.props.isResizing ||
      prevProps.showSidebar !== this.props.showSidebar
    ) {
      this.setState({ loader: true });

      setTimeout(() => {
        this.setState({ loader: false });
      }, 300);
    }
    if (this.props.location.search !== prevProps.location.search) {
      this.checkParams();
    }
  }

  checkParams = () => {
    const { paramsArray } = this.state;
    const searchParams = new URLSearchParams(this.props.location.search);
    const showDiv = paramsArray.includes(
      searchParams.get('ttm') || 'State of Charge'
    );
    const checkboxChecked = searchParams.get('threshold');
    this.setState({ showThreshold: checkboxChecked, showDiv });
  };

  handleCheckboxChange = () => {
    const searchParams = new URLSearchParams(this.props.location.search);

    this.setState({
      showThreshold: !searchParams.get('showThreshold'),
    });
    if (!this.state.showThreshold) {
      searchParams.set('threshold', 'true');
    } else {
      searchParams.delete('threshold');
    }
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: searchParams.toString(),
    });
  };

  render() {
    const { isFixed, showDiv } = this.state;

    return (
      <div
        className="tabfleetview"
        id="vehicle-products-tab"
        data-intro="string_overview">
        <Tabs
          id="fleet"
          style={{ width: this.props.heatmap_min_width - 5 }}
          className={`main-tab-content${isFixed ? ' fixed' : ''}`}
          activeKey={this.props.selectedTab}
          onSelect={(k, ev) =>
            ev.type === 'click'
              ? this.props.onVehicleProductsViewTabClick(k)
              : null
          }>
          {checkUserEntitlements(
            'ESS:Product-Overview',
            'Overview-vehicleprod-view'
          ) && (
            <Tab
              eventKey="Overview-vehicleprod-view"
              title={
                <span className="tabtitle" title="Overview">
                  Overview
                </span>
              }>
              <div className="custom-tab-content first-tab-content haveinnertabs">
                {this.props.loadingBatteryOverview === true ||
                this.props.loadingBatteryOverallCondition === true ||
                this.props.batteryOverallConditionData === null ||
                this.props.batteryOverviewData === null ||
                this.props.loadingBatteryRel === true ||
                this.props.loadingBatteryStr === true ||
                this.props.batteryRelData === null ||
                this.props.batteryStrData === null ||
                this.state.loader ? (
                  sessionStorage.getItem('selectedProductDname') !== null ? (
                    sessionStorage
                      .getItem('selectedProductDname')
                      .includes('New_Product') === false ? (
                      <Loading loadintText="Loading data" />
                    ) : (
                      <Col md={12} className="addMarginTop nodatafound">
                        <div> Sorry...</div>
                        <div className="addMarginTop">No data found !</div>
                      </Col>
                    )
                  ) : (
                    <Loading loadintText="Loading data" />
                  )
                ) : (
                  <ProductStatusTabs
                    batteryOverallConditionData={
                      this.props.batteryOverallConditionData
                    }
                    batteryRelData={this.props.batteryRelData}
                    batteryStrData={this.props.batteryStrData}
                    redirectToTimetrend={this.props.redirectToTimetrend}
                    batteryOverviewData={this.props.batteryOverviewData}
                    selectedOverviewTab={this.props.selectedOverviewTab}
                    onProductOverviewTabClick={
                      this.props.onProductOverviewTabClick
                    }
                    userConfig={this.props.userConfig}
                  />
                )}
              </div>
            </Tab>
          )}
          {checkUserEntitlements('ESS:Product-Usage', 'Usage') && (
            <Tab
              eventKey="Usage"
              title={
                <span
                  className="tabtitle"
                  title="Usage"
                  data-intro="usage-report">
                  Usage
                </span>
              }>
              <div
                data-intro="usage-content"
                className="custom-tab-content first-tab-content haveinnertabs usagestab">
                {this.state.loader || this.props.loadingUsgPowHistogram ? (
                  <Loading loadintText="Loading data" />
                ) : (
                  <ProductUsagesTabs
                    width={this.props.heatmap_min_width - 107}
                    usgsocShowHeatmap={this.props.usgsocShowHeatmap}
                    usgtempShowHeatmap={this.props.usgtempShowHeatmap}
                    usgdodShowHeatmap={this.props.usgdodShowHeatmap}
                    usgpowShowHeatmap={this.props.usgpowShowHeatmap}
                    handleSwitchChange={this.props.handleSwitchChange}
                    entitlements={
                      this.props.userConfig.entitlements.product.usage
                    }
                    loadingTemperatureHistogram={
                      this.props.loadingTemperatureHistogram
                    }
                    loadingOperatingHoursHistogram={
                      this.props.loadingOperatingHoursHistogram
                    }
                    usgTempHeatmapData={this.props.usgTempHeatmapData}
                    usgSocHeatmapData={this.props.usgSocHeatmapData}
                    usgDodHeatmapData={this.props.usgDodHeatmapData}
                    usgPowHeatmapData={this.props.usgPowHeatmapData}
                    loadingUsgDodHeatmap={this.props.loadingUsgDodHeatmap}
                    loadingUsgPowHeatmap={this.props.loadingUsgPowHeatmap}
                    loadingUsgTempHeatmap={this.props.loadingUsgTempHeatmap}
                    loadingUsgSocHeatmap={this.props.loadingUsgSocHeatmap}
                    temperatureHistogramData={
                      this.props.temperatureHistogramData
                    }
                    operatingHoursHistogramData={
                      this.props.operatingHoursHistogramData
                    }
                    selectedUsageTab={this.props.selectedUsageTab}
                    selectedUsageRbtn={this.props.selectedUsageRbtn}
                    onUsageTabsClick={this.props.onUsageTabsClick}
                    loadingUsgSocHistogram={this.props.loadingUsgSocHistogram}
                    usgSocHistogramData={this.props.usgSocHistogramData}
                    loadingUsgPowHistogram={this.props.loadingUsgPowHistogram}
                    usgPowHistogramData={this.props.usgPowHistogramData}
                    loadingUsgDodHistogram={this.props.loadingUsgDodHistogram}
                    usgDodHistogramData={this.props.usgDodHistogramData}
                    expandSide={this.props.expandSide}
                    sideWidth={this.props.sideWidth}
                    setRadioButtonValue={this.props.setRadioButtonValue}
                    radioValue={this.props.radioValue}
                    onHeatmapBlockClickHandler={
                      this.props.onHeatmapBlockClickHandler
                    }
                  />
                )}
              </div>
            </Tab>
          )}

          {checkUserEntitlements(
            'ESS:Product-Socrange',
            'SoC-range-usage-vehicleprod-view'
          ) && (
            <Tab
              eventKey="SoC-range-usage-vehicleprod-view"
              title={
                <span className="tabtitle" title="SoC Range usage">
                  SoC Range usage
                </span>
              }>
              <div className="custom-tab-content">
                {this.props.loadingBatterySOCRange === true ||
                this.props.batterySOCRangeData === null ||
                this.state.loader ? (
                  sessionStorage.getItem('selectedProductDname') !== null ? (
                    sessionStorage
                      .getItem('selectedProductDname')
                      .includes('New_Product') === false ? (
                      <Loading loadintText="Loading data" />
                    ) : (
                      <Col md={12} className="addMarginTop nodatafound">
                        <div> Sorry...</div>
                        <div className="addMarginTop">No data found !</div>
                      </Col>
                    )
                  ) : (
                    <Loading loadintText="Loading data" />
                  )
                ) : this.props.batterySOCRangeData.sr.length > 0 ? (
                  <>
                    <SoCTable
                      pageName="batterySOCRange"
                      data={this.props.batterySOCRangeData.sp}
                    />
                    <SingleGraph
                      chartType="BATTERY_SOC_RANGE_HISTOGRAM_TYPE"
                      data={this.props.batterySOCRangeData.sr}
                      expandSide={this.props.expandSide}
                      sideWidth={this.props.sideWidth}
                    />
                  </>
                ) : (
                  <Col md={12} className="addMarginTop nodatafound">
                    <div> Sorry...</div>
                    <div className="addMarginTop">No data found !</div>
                  </Col>
                )}
              </div>
            </Tab>
          )}
          <Tab
            eventKey="State-of-charge-vehicleprod-view"
            title={
              <span
                className="tabtitle hidewithoutspace"
                title="State of charge">
                State of charge
              </span>
            }>
            <div className="custom-tab-content">
              {this.props.loadingBatteryStateOfCharge === true ||
              this.props.batteryStateOfChargeData === null ||
              this.state.loader ? (
                sessionStorage.getItem('selectedProductDname') !== null ? (
                  sessionStorage
                    .getItem('selectedProductDname')
                    .includes('New_Product') === false ? (
                    <Loading loadintText="Loading data" />
                  ) : (
                    <Col md={12} className="addMarginTop nodatafound">
                      <div> Sorry...</div>
                      <div className="addMarginTop">No data found !</div>
                    </Col>
                  )
                ) : (
                  <Loading loadintText="Loading data" />
                )
              ) : this.props.batteryStateOfChargeData.dc.length > 0 ? (
                <SingleGraph
                  chartType="SOC_TREND_TYPE"
                  data={this.props.batteryStateOfChargeData}
                  expandSide={this.props.expandSide}
                  sideWidth={this.props.sideWidth}
                />
              ) : (
                <Col md={12} className="addMarginTop nodatafound">
                  <div> Sorry...</div>
                  <div className="addMarginTop">No data found !</div>
                </Col>
              )}
            </div>
          </Tab>
          <Tab
            eventKey="Cell-voltage-vehicleprod-view"
            title={
              <span className="tabtitle hidewithoutspace" title="Cell voltage">
                Load cycle
              </span>
            }>
            <div className="custom-tab-content">
              {this.props.loadingCellvoltage === true ||
              this.props.cellVoltageData === null ||
              this.state.loader ? (
                sessionStorage.getItem('selectedProductDname') !== null ? (
                  sessionStorage
                    .getItem('selectedProductDname')
                    .includes('New_Product') === false ? (
                    <Loading loadintText="Loading data" />
                  ) : (
                    <Col md={12} className="addMarginTop nodatafound">
                      <div> Sorry...</div>
                      <div className="addMarginTop">No data found !</div>
                    </Col>
                  )
                ) : (
                  <Loading loadintText="Loading data" />
                )
              ) : this.props.cellVoltageData.length > 0 ? (
                <SingleGraph
                  chartType="CELL_VOLTAGE_TREND_TYPE"
                  data={this.props.cellVoltageData}
                  expandSide={this.props.expandSide}
                  sideWidth={this.props.sideWidth}
                />
              ) : (
                <Col md={12} className="addMarginTop nodatafound">
                  <div> Sorry...</div>
                  <div className="addMarginTop">No data found !</div>
                </Col>
              )}
            </div>
          </Tab>
          <Tab
            eventKey="synched-chart-vehicleprod-view"
            title={
              <span
                className="tabtitle hidewithoutspace"
                title="Synchronized graph">
                Synchronized graph
              </span>
            }>
            <SignalSelector
              mainSignalValues={mainSignalTrendOptions}
              overlaySignalValues={overlaySignalOptions}
              selectedMainSignal={this.props.selectedMainSignal}
              selectedOverlaySignal={this.props.selectedOverlaySignal}
              selectedMainSignal1={this.props.selectedMainSignal1}
              selectedOverlaySignal1={this.props.selectedOverlaySignal1}
              selectedMainSignal2={this.props.selectedMainSignal2}
              selectedOverlaySignal2={this.props.selectedOverlaySignal2}
              selectedMainSignal3={this.props.selectedMainSignal3}
              selectedOverlaySignal3={this.props.selectedOverlaySignal3}
              handleSyncMainDropdownChange={
                this.props.handleSyncMainDropdownChange
              }
              handleSyncOverlayDropdownChange={
                this.props.handleSyncOverlayDropdownChange
              }
              handleSyncToggleChange={this.props.handleSyncToggleChange}
              selectedSyncChart={this.props.selectedSyncChart}
              chartType={'SYNC_TYPE'}
            />
            <div className="custom-tab-content">
              {this.props.loadingSyncGraph === true ||
              this.props.syncGraphData === null ? (
                sessionStorage.getItem('selectedProductDname') !== null ? (
                  sessionStorage
                    .getItem('selectedProductDname')
                    .includes('New_Product') === false ? (
                    <Loading loadintText="Loading data" />
                  ) : (
                    <Col md={12} className="addMarginTop nodatafound">
                      <div> Sorry...</div>
                      <div className="addMarginTop">No data found !</div>
                    </Col>
                  )
                ) : (
                  <Loading loadintText="Loading data" />
                )
              ) : this.props.syncGraphData.length > 0 ? (
                <SynchronizedGraph
                  data={this.props.syncGraphData}
                  selectedMainSignal={this.props.syncGraphMain}
                  selectedOverlaySignal={this.props.syncGraphOverlay}
                />
              ) : (
                <Col md={12} className="addMarginTop nodatafound">
                  <div> Sorry...</div>
                  <div className="addMarginTop">No data found !</div>
                </Col>
              )}
              {/* <SynchronizedGraph /> */}
            </div>
          </Tab>
          {checkUserEntitlements(
            'ESS:Product-Timetrend',
            'Timetrend-vehicleprod-view'
          ) && (
            <Tab
              eventKey="Timetrend-vehicleprod-view"
              title={
                <span
                  className="tabtitle"
                  title="Time trend"
                  data-intro="time-trend-title">
                  Time Trend
                </span>
              }>
              {this.state.loader ? (
                <Loading loadintText="Loading data" />
              ) : (
                <>
                  <SignalSelector
                    mainSignalValues={
                      sessionStorage.getItem('selectedFleet') ===
                      'ABB ESS Demo Fleet'
                        ? mainSignalTrendOptionsDemo
                        : this.props.userConfig.entitlements.product
                            .trendSignals
                    }
                    overlaySignalValues={
                      sessionStorage.getItem('selectedFleet') ===
                      'ABB ESS Demo Fleet'
                        ? overlaySignalOptionsDemo
                        : this.props.userConfig.entitlements.product
                            .trendSignals
                    }
                    selectedMainTrend={this.props.selectedMainTrend}
                    selectedOverlayTrend={this.props.selectedOverlayTrend}
                    // mainSignalValues={mainSignalTrendOptions}
                    // overlaySignalValues={overlaySignalOptions}
                    // selectedMain={this.props.selectedMainTrend}
                    // selectedOverlays={this.props.selectedOverlaysTrend}
                    // clickMainSignal={this.props.clickMainSignalTrend}
                    // clickOverlaySignal={this.props.clickOverlaySignalTrend}
                    clickTrendMainSignal={this.props.clickTrendMainSignal}
                    clickTrendOverlaySignal={this.props.clickTrendOverlaySignal}
                    chartType={'TREND_TYPE'}
                  />
                  <div className="lock-zoom-con">
                    <div>
                      <span className="boldText">Zoomed interval:</span>
                      <span className={'visible zoomed-text'}>
                        {/* <span>02.03.2021, 18:30 to 03.03.2021, 18:29 - 1 day</span> */}
                        <span>{this.props.startZoomInterval}</span>
                        <span className="small-space">to</span>
                        <span>{this.props.endZoomInterval}</span>
                        <span className="small-space">-</span>
                        <span>{this.props.lockedZoomDuration}</span>
                      </span>
                      <span className="zoomed-btns">
                        <button
                          className={' btn  btn-sm  btn-link-custom visible'}
                          onClick={() => {
                            this.props.onLoadMoreGraph('', '', '', true);
                          }}
                          disabled={
                            (sessionStorage.getItem('zoomIntervalStartDate') ===
                              'null' &&
                              sessionStorage.getItem('zoomIntervalEndDate') ===
                                'null') ||
                            this.props.isButtonDisabled
                          }
                          id={'backzoom'}
                          title={'Back to previous sample rate'}>
                          Back
                        </button>
                        <button
                          className={' btn  btn-sm  btn-link-custom visible'}
                          onClick={this.props.onLoadMoreGraph}
                          disabled={
                            sessionStorage.getItem('isTrendZoomed') !== 'true'
                          }
                          id="resetzoom">
                          Reset
                        </button>
                      </span>
                    </div>
                    {showDiv && (
                      <div
                        data-intro="main_signal_threshold"
                        style={{
                          display: 'flex',
                          gap: '8px',
                        }}>
                        <input
                          type="checkbox"
                          onChange={this.handleCheckboxChange}
                          checked={this.state.showThreshold}
                        />
                        <span>Show main signal threshold</span>

                        <button
                          data-intro="glossary-icon"
                          title="Glossary Icon"
                          className="infoIconTime"
                          onClick={this.openGlossary}>
                          <IconInfo color={'black'} />
                        </button>
                      </div>
                    )}
                  </div>

                  <Row className="clickdragtext">
                    <Col className="drag-msg">
                      <FormattedMessage {...messages.clickDragText} />
                    </Col>
                  </Row>
                  <Row className="timetrendchart">
                    <Col md={12} data-intro="time-trend-graph">
                      {this.props.loadingTimetrend === true ||
                      this.props.timetrendData === null ||
                      this.props.timetrendData === undefined ? (
                        sessionStorage.getItem('selectedProductDname') !==
                        null ? (
                          sessionStorage
                            .getItem('selectedProductDname')
                            .includes('New_Product') === false ? (
                            <Loading loadintText="Loading data" />
                          ) : (
                            <Col md={12} className="addMarginTop nodatafound">
                              <div> Sorry...</div>
                              <div className="addMarginTop">
                                No data found !
                              </div>
                            </Col>
                          )
                        ) : (
                          <Loading loadintText="Loading data" />
                        )
                      ) : this.props.timetrendData.length > 0 ? (
                        this.props.selectedTab ===
                          'Timetrend-vehicleprod-view' && (
                          <Fragment>
                            <div id="chartLoader">
                              {/* <Loading /> */}
                              <div id="overlay" onClick={() => 'off()'}>
                                <div id="loader">
                                  <Loading loadintText="Loading ..." />
                                </div>
                              </div>
                            </div>
                            <SingleGraph
                              chartType="TIME_TREND_TYPE" // "CELL_VOLTAGE_TREND_TYPE" //
                              // data={this.props.cellVoltageData}
                              data={this.props.timetrendData}
                              selectedMainTrend={this.props.selectedMainTrend}
                              selectedOverlayTrend={
                                this.props.selectedOverlayTrend
                              }
                              showThreshold={this.state.showThreshold}
                              onLoadMoreGraph={
                                this.props.onLoadMoreGraph &&
                                this.props.onLoadMoreGraph
                              }
                              sideWidth={this.props.sideWidth}
                              expandSide={this.props.expandSide}
                              info={this.props.batteryOverallConditionData}
                            />
                          </Fragment>
                        )
                      ) : (
                        <Col md={12} className="addMarginTop nodatafound">
                          <div> Sorry...</div>
                          <div className="addMarginTop">No data found !</div>
                        </Col>
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </Tab>
          )}
          {checkUserEntitlements('ESS:Product-SOH', 'SOH-vehicleprod-view') && (
            <Tab
              eventKey="SOH-vehicleprod-view"
              title={
                <span
                  className="tabtitle"
                  title="State of health"
                  data-intro="state_of_health_title">
                  State of Health
                </span>
              }>
              <Row className="timetrendchart">
                <Col md={12}>
                  <Fragment>
                    <div style={{ height: '100%' }} data-intro="single_graph">
                      {this.props.loadingSOH === true ||
                      this.state.loader ||
                      this.props.SOHData === null ||
                      this.props.SOHData === undefined ? (
                        <Loading loadintText="Loading data" />
                      ) : this.props.SOHData.lastUpdatedOn === null ? (
                        <Col md={12} className="addMarginTop nodatafound">
                          <div> Sorry...</div>
                          <div className="addMarginTop">No data found !</div>
                        </Col>
                      ) : (
                        <div>
                          <button
                            data-intro="glossary-icon"
                            title="Glossary Icon"
                            className="infoIcon"
                            onClick={this.openGlossary}>
                            <IconInfo color={'black'} />
                          </button>
                          <SingleGraph
                            chartType="SOH_TYPE"
                            data={this.props.SOHData}
                            expandSide={this.props.expandSide}
                            sideWidth={this.props.sideWidth}
                          />
                        </div>
                      )}
                    </div>
                  </Fragment>
                </Col>
              </Row>
            </Tab>
          )}
          {this.props.userConfig !== undefined &&
            (sessionStorage.getItem('selectedFleet') === 'ABB ESS Demo Fleet'
              ? checkUserEntitlements(
                  'ESS:Demo-Events',
                  'Event-list-vehicleprod-view'
                )
              : checkUserEntitlements(
                  'ESS:Product-Events',
                  'Event-list-vehicleprod-view'
                )) && (
              <Tab
                eventKey="Event-list-vehicleprod-view"
                title={
                  <span className="tabtitle" title="Event list">
                    Events List
                  </span>
                }
                className={'event_minheight'}>
                <div
                  className="custom-tab-content"
                  id="product-evnt-list"
                  style={{
                    // position: "absolute",
                    width: this.props.heatmap_min_width - 5,
                  }}>
                  {/* {this.props.loadingEventList === true ||
                  this.props.eventList === null ? (
                    <Loading loadintText="Loading data" />
                  ) : this.props.eventList.length > 0 ? ( */}
                  <>
                    <EventList
                      eventListConfig={
                        this.props.eventListConfig && this.props.eventListConfig
                      }
                      eventData={this.props.eventData}
                      setAllEventListData={this.props.setAllEventListData}
                      fetchAllEventList={this.props.fetchAllEventList}
                      eventList={this.props.eventList}
                      handleEventInputChange={this.props.handleEventInputChange}
                      handleEventRadioChange={this.props.handleEventRadioChange}
                      setEventUpdateDate={this.props.setEventUpdateDate}
                      setEventNewPec={this.props.setEventNewPec}
                      eventFormValues={this.props.eventFormValues}
                      handleClose={this.props.handleClose}
                      handleEventSubmit={this.props.handleEventSubmit}
                      loadingSaveEvent={this.props.loadingSaveEvent}
                      saveEventResponce={this.props.saveEventResponce}
                      handleUndoSubmit={this.props.handleUndoSubmit}
                      loadingUndoEvent={this.props.loadingUndoEvent}
                      undoEventResponce={this.props.undoEventResponce}
                      selectedView={this.props.selectedView}
                      vehiclesListData={this.props.vehiclesListData}
                      eventFilterApply={this.props.eventFilterApply}
                      loadingEventList={this.props.loadingEventList}
                      selectedProduct={this.props.selectedProduct}
                      userConfig={this.props.userConfig}
                      redirectToTimetdOnStreesEvtClk={
                        this.props.redirectToTimetdOnStreesEvtClk
                      }
                    />
                  </>
                  {/* ) : (
                    <Col md={12} className="addMarginTop nodatafound">
                      <div> Sorry...</div>
                      <div className="addMarginTop">No data found !</div>
                    </Col>
                  )} */}
                </div>
              </Tab>
            )}
          {checkUserEntitlements(
            'ESS:Diagnostic-event-tab',
            'diagnostic-vehicleprod-view'
          ) && (
            <Tab
              eventKey="diagnostic-vehicleprod-view"
              title={<span className="tabtitle">Diagnostics</span>}>
              <div
                className="custom-tab-content"
                id="fleet-diag-list"
                style={{
                  // position: "absolute",
                  width: this.props.heatmap_min_width - 5,
                }}>
                <Diagnostic
                  entitlements={this.props.userConfig.entitlements}
                  selectedView={this.props.selectedView}
                  diagFilterApply={this.props.diagFilterApply}
                  vehiclesListData={this.props.vehiclesListData}
                  fetchDiagnosticList={this.props.fetchDiagnosticList}
                  loadingDiagnosticList={this.props.loadingDiagnosticList}
                  diagnosticList={
                    this.props.diagnosticList && this.props.diagnosticList
                  }
                  redirectToTimetrendFrmDiagnostic={
                    this.props.redirectToTimetrendFrmDiagnostic
                  }
                  copyLinkDiagnosticTab={this.props.copyLinkDiagnosticTab}
                  diagnosticListConfig={
                    this.props.diagnosticListConfig &&
                    this.props.diagnosticListConfig
                  }
                  diagnosticEventData={
                    this.props.diagnosticEventData &&
                    this.props.diagnosticEventData
                  }
                  setDiagnosticEventListData={
                    this.props.setDiagnosticEventListData
                  }
                />
              </div>
            </Tab>
          )}
        </Tabs>
      </div>
    );
  }
}
const mapDispatchToProps = {
  setGlossaryStateOpen,
  setGlossaryTag,
};

export default withRouter(
  connect(null, mapDispatchToProps)(VehicleProductsTabs)
);
